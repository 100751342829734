/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Layout from '../components/Layout/index';
import SectionHeader from '../components/Common/SectionHeader';
import { Seo } from '../components/Seo';

const privacy = () => {
  return (
    <Layout>
      <Seo
        title="Privacy Policy | National Embryo Donation Center"
        description={
          'NEDC knows that you care how information about you is used and shared. We respect your privacy and appreciate the trust that you have in our company. We will not rent or sell your name, address, email address, credit information or personal information to any third party without your permission. Should a situation arise where we […]'
        }
      />
      <section>
        <div
          className="text-content"
          sx={{
            mx: 'auto',
            h4: {
              mb: '0.5rem',
              variant: 'text.lg',
            },
            p: {
              mb: '1rem',
              variant: 'text.normal',
            },
          }}
        >
          <SectionHeader heading={'Privacy Policy'} styles={{ pt: [null, null, null, '3rem'] }} />
          <p>
            NEDC knows that you care how information about you is used and shared. We respect your
            privacy and appreciate the trust that you have in our company. We will not rent or sell
            your name, address, email address, credit information or personal information to any
            third party without your permission. Should a situation arise where we are required by
            law or legal process to provide information about a customer, we will comply with that
            request.
          </p>
          <p>
            If you are a customer of ours or have registered to receive our newsletter, we may
            occasionally email you with special opportunities.
          </p>
          <p>
            We may share non-personal, non-individual statistical or demographic information in
            aggregate form with our marketing partners, advertisers or other third parties for
            research purposes.
          </p>
          <p>
            Given the rapid changes in offerings and technologies, we reserve the right to change,
            modify, add or remove portions of our Privacy Policy at any time without prior notice.
            If these changes are substantial, we will notify you via email. You are free to review
            our Privacy Policies for changes at any time.
          </p>
          <p>
            If you have any questions regarding our privacy practices, please feel free to contact
            us.
          </p>
          <p>Thank you!</p>
          <p>
            {' '}
            <a
              href="https://storage.googleapis.com/dega.factly.in/nedc/2015/2/2014-final-audit.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              2014 Audit
            </a>
          </p>
          <p>
            <a
              href="https://storage.googleapis.com/dega.factly.in/nedc/2015/2/fye-6-30-14.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              990
            </a>
          </p>
          <h4>Whistle Blower Policy</h4>
          <p>
            The National Embryo Donation Center (NEDC) is committed to operating in furtherance of
            its tax-exempt purposes and in compliance with all applicable laws, rules and
            regulations, including those concerning accounting and auditing, and prohibits
            fraudulent practices by any of its board members, officers, employees, or volunteers.
            This policy outlines a procedure for employees to report actions that an employee
            reasonably believes violates a law, or regulation or that constitutes fraudulent
            accounting or other practices. This policy applies to any matter which is related to the
            NEDC’s business and does not relate to private acts of an individual not connected to
            the business of the NEDC.
          </p>
          <p>
            If an employee has a reasonable belief that an employee of the NEDC has engaged in any
            action that violates any applicable law or regulation, including those concerning
            accounting and auditing, or constitutes a fraudulent practice, the employee is expected
            to immediately report such information to the President. If the employee does not feel
            comfortable reporting the information to the President, he or she is expected to report
            the information to the Board of Directors.
          </p>
          <p>
            All reports will be followed up promptly and an investigation conducted. In conducting
            its investigations, the NEDC will strive to keep the identity of the complaining
            individual as confidential as possible, while conducting an adequate review and
            investigation.
          </p>
          <p>
            The NEDC will not retaliate against an employee in the terms and conditions of
            employment because that employee: (a) reports to a supervisor, to the executive
            director, the Board of Directors or to a federal, state or local agency what the
            employee believes in good faith to be a violation of the law; or (b) participates in
            good faith in any resulting investigation or proceeding, or (c) exercises his or her
            rights under any state or federal law(s) or regulation(s) to pursue a claim or take
            legal action to protect the employee’s rights.
          </p>
          <p>
            The NEDC may take disciplinary action (up to and including termination) against an
            employee who in management’s assessment has engaged in conduct in violation of this
            policy. Conversely, if there is substantial evidence that an employee has acted in bad
            faith in reporting a violation of law, regulations, or practices, disciplinary action up
            to and including termination may also be taken against said employee.
          </p>
          <p>
            Management will be trained on this policy and the NEDC’S prohibition against retaliation
            in accordance with this policy.
          </p>
        </div>
      </section>
    </Layout>
  );
};

export default privacy;
